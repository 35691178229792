.mdl-card.firebaseui-container {
  box-shadow: none;
}
.mdl-card.firebaseui-container.firebaseui-page-provider-sign-in {
  box-shadow: none;
}
.mdl-card.firebaseui-container.firebaseui-id-page-callback {
  box-shadow: none;
}
.firebaseui-form-actions
  .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background-color: rgb(245, 0, 87);
  border-radius: 4px;
}
.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: rgb(245, 0, 87) !important;
}
a.firebaseui-link {
  color: rgb(245, 0, 87) !important;
}
.mdl-button.mdl-js-button.mdl-button--primary {
  color: rgb(245, 0, 87) !important;
}
.progressbar {
  background-color: rgb(245, 0, 87) !important;
}
